import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 日报管理API接口
// --------------------------------------------

export const departMent = async () => {
  return await $.get({
    url: 'admin/common/departMent',
  })
}
