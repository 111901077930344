<template>
  <div>
    <el-dialog title="人员列表" :visible.sync="isShow" width="700px" append-to-body>
      <div>
        <div class="search">
          <div class="item">
            <span>姓名：</span>
            <el-input
              v-model="searchForm.name"
              class="senarch-input"
              placeholder="请输入内容"
              size="mini"
              clearable
            />
          </div>
          <div class="item">
            <span>所属单位：</span>
            <el-cascader
              clearable
              size="mini"
              class="input"
              v-model="searchForm.unit_id"
              :options="select.unit_id"
              :props="{ expandTrigger: 'hover',checkStrictly: true ,label:'name',value:'id' }"
            ></el-cascader>
          </div>
          <div class="item">
            <el-button type="primary" class="search-button" size="mini" @click="search">搜索</el-button>
          </div>
        </div>
      </div>
      <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        @current-change="handleCurrentChange"
        style="width: 100%"
        height="250"
      >
        <el-table-column property="nickname" label="姓名"></el-table-column>
        <el-table-column property="unit_id.unit" label="单位"></el-table-column>
        <el-table-column property="unit_id.group" label="分组"></el-table-column>
      </el-table>

      <div v-if="format.count !== 0" class="page">
        <el-pagination
          background
          layout="total,prev, pager, next,jumper"
          :page-sizes="[10, 20, 30, 40]"
          :pager-count="format.pagerCount"
          :total="format.count"
          :page-size="format.limit"
          :current-page.sync="format.page"
          @current-change="change"
        />
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="confirm">确 定</el-button>
        <el-button @click="isShow = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { getUser, userDetail } from "@/api/user"
import { departMent } from "@/api/select"

export default {
  components: {},
  props: {
    choice: Function,
  },

  data() {
    return {
      tableData: [],
      currentRow: null,
      isShow: false,
      searchForm: {},
      select: [],
      val: {},
      format: {
        count: 0,
        limit: 10,
        page: 1,
      },
      searchForm: {},
      select: {},
    }
  },
  mounted() {
    this.getList()
    this.getSelect()
  },
  methods: {
    // 获取列表
    getList(query) {
      let form = { ...this.format }
      if (query) {
        Object.assign(form, query)
        if (Array.isArray(form.unit_id))
          form.unit_id = form.unit_id[form.unit_id.length - 1]
      }
      getUser(form).then((res) => {
        if (res.status !== 200) {
          this.$message.error(res.message)
          return
        }
        this.format.count = res.result.count
        this.tableData = res.result.data
      })
    },
    // 获取选项
    getSelect() {
      departMent().then((res) => {
        this.$set(this.select, "unit_id", res.result)
      })
    },
    // 点击确认后把选中的数据返还给父组件
    confirm() {
      userDetail({ id: this.val.id }).then((res) => {
        if (res.status !== 200) {
          this.$message.error("获取人员失败")
        }
        this.choice(res.result)
      })

      this.isShow = false
    },
    open: function () {
      this.isShow = true
    },
    close: function () {
      this.isShow = false
    },
    handleCurrentChange(val) {
      this.val = val
    },
    change(pageNumber) {
      this.getList()
    },
    search() {
      this.getList(this.searchForm)
    },
  },
}
</script>

<style lang="stylus" scoped>
.search {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  .item {
    margin-right: 15px;
    margin-bottom:15px ;
    span {
      line-height: 28px;
    }
    .senarch-input{
      width: 200px;
    }
  }
  .search-button {
    margin-left: 15px;
  }
}
.page {
  margin-top 15px;
  display flex;
  justify-content flex-end;

}

</style>
