import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 用户API接口
// --------------------------------------------

/**
 * 获取用户列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getUser = async data => {
  return await $.get({
    url: 'admin/user/userList',
    data,
    isLoad: true,
  })
}

/**
 * 获取单位管理
 * @param {Object} data 详看接口文档
 */
export const getUnit = async data => {
  return await $.get({
    url: 'admin/unit/unitList',
    data,
  })
}

export const userDetail = async data => {
  return await $.get({
    url: 'admin/user/userDetail',
    data,
  })
}
