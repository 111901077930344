import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 公共 API接口
// --------------------------------------------

/**
 * 上传文件
 * @param {File} file 文件对象
 */
export const uploadFile = async file => {
  return await $.upload({
    url: 'admin/setting/config/upload',
    file,
  })
}

/**
 * 获取配置信息
 * @param {String} type 类型
 */
export const getConfig = async type => {
  return $.get({
    url: 'admin/select/getTypeList',
    data: { type },
  })
}

/**
 * 获取单兵设备下拉框
 */
export const getDevSelect = async () => {
  return $.get({
    url: 'admin/common/signle',
  })
}

/**
 * 获取 sessionId
 * @param {String} member 设备号
 */
export const getDevLive = async member => {
  return $.get({
    url: 'admin/bind/singleLive',
    data: { unit_member: member },
  })
}

/**
 * 获取单位列表
 */
export const getUnitSelect = async () => {
  return $.get({
    url: 'admin/select/getUnitList',
  })
}

/**
 * 获取工程下拉框
 */
export const getPSelect = async () => {
  return $.get({
    url: 'admin/bind/bindProject',
  })
}

/**
 * 获取设备下拉框
 * @param {String} type 设备所属类型 1 摄像头  2单兵  3手环  4气体检测  5环境检测   6人脸设备
 */
export const getUSelect = async type => {
  return $.get({
    url: 'admin/bind/bindUnit',
    data: { type },
  })
}

export const getProjetS = async () => {
  return $.get({
    url: '/admin/select/getProjectList',
  })
}
