<template>
  <el-dialog
   :close-on-click-modal='false'
   :close-on-press-escape='false'
   :destroy-on-close='true'
   :show-close='false'
   width="48%" title="选择位置" :visible.sync="isShow" append-to-body>
    <div>
      <el-autocomplete
        class="search"
        v-model="address"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入位置"
        @select="handleSelect"
      >
        <template slot-scope="{ item }">
          <div class="item-search">
            <i style="margin-top: 5px;" class="el-icon-search fl mgr10"></i>
            <div style="overflow: hidden; margin-left: 10px; line-height:20px; margin-bottom: 8px;">
              <div class="title">{{ item.title }}</div>
              <span class="address">{{ item.address }}</span>
            </div>
          </div>
        </template>
      </el-autocomplete>
    </div>
    <baidu-map
      style="width: 100%; height: 340px"
      :ak="mapStyle.AK"
      :scroll-wheel-zoom="mapStyle.SCROLLWHEELZOOM"
      :center="center"
      :zoom="zoom"
      @ready="handler"
      @click="getPoint"
    >
      <map-marker :position="current"> </map-marker>
    </baidu-map>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map' // 地图组件
import MapMarker from 'vue-baidu-map/components/overlays/Marker' // 地图点
import { MAP_STYLE } from '@/utils/configMap'
export default {
  name: 'selectMap',
  components: {
    BaiduMap,
    MapMarker,
  },
  props: {
    localChange: Function,
  },
  data() {
    return {
      mapStyle: MAP_STYLE,
      isShow: false,
      center: {}, // 中心点
      zoom: 15, // 级别
      address: '', // 地址
      current: {}, // 当前Ponit点
      BMap: {}, // 地图实例
      map: {}, // 地图组件
    }
  },
  methods: {
    open: function() {
      this.isShow = true
    },
    close: function() {
      this.isShow = false
    },
    submit: function() {
      this.close()
      this.localChange && this.localChange({
        address: this.address,
        location: this.current,
      })
    },
    // 初始化地图
    handler: function ({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      this.center = {
        lng: 120.514466,
        lat: 30.319445,
      }
      this.zoom = 15
    },

    // 获取当前坐标点
    getPoint: function ({ point }) {
      this.current = point
      const geo = new this.BMap.Geocoder()
      // console.log(point, '这是？')
      geo.getLocation(point, res => {
        // console.log(res, '这是？')
        this.address = res.address
      }, err => {
        console.log(err, '失败')
      })
    },

    // 搜索周边经纬度
    querySearchAsync: function (str, cb) {
      const local = new this.BMap.LocalSearch(this.map, {
        onSearchComplete: (res) => {
          cb(res.Hr)
        },
      })
      console.log(str, '::: ')
      local.search(str)
    },

    // 选择位置
    handleSelect: function({ address, point }) {
      this.address = address
      this.current = point
      this.center = point
    },
  },
}
</script>

<style lang="stylus" scoped>
.search {
  width: 100%;
  margin-bottom: 20px;
}
.item-search{
  width 100%;
  display flex;
  // align-items center;
  .address{
    font-size 10px;
    color #999
  }
}
</style>
