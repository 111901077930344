<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="35%"
    title="编辑文件夹"
    :visible.sync="isShow"
  >
    <div class="all-input">
      <div class="item-input">
        <div class="label"><span class="red">*</span>文件夹名称：</div>
        <el-input v-model="formData.name" class="mini"></el-input>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'editFile',
  props: {
    doneChange: Function,
  },
  data() {
    return {
      isShow: false, // 是否打开弹窗
      formData: {
        name: '', // 标题
      },
      toastData: {
        name: '请输入分类名称', // 标题
      },
      isEdit: true,
    }
  },
  created() {
  },
  methods: {
    open: function (data) {
      this.isShow = true
      if (data) {
        this.formData = data
      }
    },
    close: function (isLoad) {
      this.isShow = false
      this.clearFormData()
    },
    submit: function () {
      for (const key in this.toastData) {
        if (!this.formData[key]) {
          this.$toast(this.toastData[key])
          return false
        }
      }

      this.doneChange && this.doneChange(this.formData)
      this.close()
    },

    clearFormData: function() {
      for (const key in this.formData) {
        this.formData[key] = ''
      }
    },
  },
}
</script>

<style lang="stylus" scoped></style>
